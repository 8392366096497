import '@babel/polyfill';

import '../scss/common.scss';
import '../scss/top.scss';
import '../scss/about.scss';
import '../scss/opensource.scss';
import '../scss/products.scss';
import '../scss/alliance.scss';
import '../scss/ourteam.scss';
import '../scss/career.scss';
import '../scss/media.scss';
import '../scss/media_detail.scss';
import '../scss/privacy.scss';
import '../scss/contact.scss';
import '../scss/404.scss';
import '../scss/top_ja.scss';
import '../scss/about_ja.scss';
import '../scss/opensource_ja.scss';
import '../scss/products_ja.scss';
import '../scss/alliance_ja.scss';
import '../scss/ourteam_ja.scss';
import '../scss/career_ja.scss';
import '../scss/media_detail_ja.scss';
import '../scss/cookie_policy.scss';

import Config from './Config';
import Common from './Common';

require('./libs/TweenMax.min.js');
require('./libs/daijima/trace.js');
require('./libs/daijima/requestanimationframe.js');

let packName = 'TIER4';
let pack = new Config(window, packName);

//CSS Modulesとして使用する場合
// import * as styles from "../scss/common.scss";

new Common(pack);

require('./barba-custom.js');
