import TemplateContents from './TemplateContents';

import Header from './Header';
import Footer from './Footer';
import Loading from './Loading';
import Background from './Background';
import Transition from './Transition';
import Top from './Top';
import About from './About';
import Products from './Products';
import Opensource from './Opensource';
import Alliance from './Alliance';
import Ourteam from './Ourteam';
import Career from './Career';
import Media from './Media';
import MediaDetail from './MediaDetail';
import Privacy from './Privacy';
import Contact from './Contact';
import Page404 from './Page404';

export default class extends TemplateContents {
  constructor(param) {
    super(param);

    this.pack.common = this;
  }

  init() {
    super.init();
    // this.checkLang();

    this.header = this.pack.header = new Header(this.pack);
    this.footer = this.pack.footer = new Footer(this.pack);

    this.loadContentful();

    // this.setVars();
    // this.setDom();
    // this.initEvents();
  }

  checkLang() {
    //bot判別 botだったら以降の処理はスキップ
    let userAgent = window.navigator.userAgent.toLowerCase();
    if (userAgent.indexOf('bot') > -1) return;

    let pathname = location.pathname;

    //sessionStorage
    let lang;
    lang = sessionStorage.getItem('lang');

    let href, outputHref;
    if (this.pack.LANG === 'en') href = location.pathname.split('/en')[1];
    else if (this.pack.LANG === 'cn') href = location.pathname.split('/cn')[1];
    else href = location.pathname;
    if (href === '') href = '/';

    if (this.pack.LANG === 'en')
      outputHref = (location.pathname + location.search).split('/en')[1];
    else if (this.pack.LANG === 'cn')
      outputHref = (location.pathname + location.search).split('/cn')[1];
    else outputHref = location.pathname + location.search;
    if (outputHref === '') outputHref = '/';

    let jump = (lang, href, bySessionStorage) => {
      if (href.indexOf('media/detail') > -1) {
        if (bySessionStorage) location.href = lang + href;
        else this.pack.setSessionStorage(this.pack.LANG);
        return;
      }
      location.href = lang + href;
    };

    //ブラウザの言語判別をして飛ばす
    let language =
      (window.navigator.languages && window.navigator.languages[0]) ||
      window.navigator.language ||
      window.navigator.userLanguage ||
      window.navigator.browserLanguage;

    if (lang === null) {
      if (language === 'ja' || language === 'ja-JP' || language === 'ja-jp') {
        if (pathname !== href) jump('', outputHref, false);
      } else if (
        language === 'cn' ||
        language === 'zh' ||
        language === 'zh-CN' ||
        language === 'zh-HK' ||
        language === 'zh-TW'
      ) {
        if (pathname !== '/cn' + href) jump('/cn', outputHref, false);
      } else if (
        language === '' ||
        language === undefined ||
        language === null
      ) {
        //ブラウザ言語が空っぽの場合、何もしない
        return;
      } else {
        if (pathname !== '/en' + href) jump('/en', outputHref, false);
      }
    } else {
      //sessionStorageに設定されている言語に飛ばす
      if (lang === 'en') {
        if (pathname !== '/en' + href) jump('/en', outputHref, true);
      } else if (lang === 'cn') {
        if (pathname !== '/cn' + href) jump('/cn', outputHref, true);
      } else {
        if (pathname !== href) jump('', outputHref, true);
      }
    }
  }

  loadContentful() {
    let urlList = this.pack.CONTENTFUL_API_PATH_LIST;

    let promiseList = [];
    this.pack.contentfulJSONList = {};

    urlList.forEach((url, i) => {
      promiseList.push(
        fetch(url)
          .then((res) => {
            return res.json();
          })
          .then((res) => {
            this.pack.contentfulJSONList[
              this.pack.CONTENTFUL_API_NAME_LIST[i]
            ] = res;
          })
      );
    });

    Promise.all(promiseList).then(() => {
      trace('api loaded', this.pack.contentfulJSONList);
      this.pack.setMediaCategories();
      this.pack.setMediaValues(
        this.pack.contentfulJSONList['media'],
        this.loadedAllMedia,
        this
      );
    });
  }

  loadedAllMedia() {
    this.pack.setPickupValues();
    this.initContainer();
  }

  addScrollTarget(target) {
    this.scrollTarget = target;
  }

  removeScrollTarget() {
    this.scrollTarget = undefined;
  }

  addEnterframeTarget(target) {
    this.enterframeTarget = target;
  }

  removeEnterframeTarget() {
    this.enterframeTarget = undefined;
  }

  addEvery90framesTarget(target) {
    this.every90framesTarget = target;
  }

  removeEvery90framesTarget() {
    this.every90framesTarget = undefined;
  }

  setVars() {
    super.setVars();
    this.enterframeID;
    this.enterframeIgnoreCnt = 0;

    this.pastPageYOffset = 0;
    this.scrollYOffset = 0;
    this.pastScrollYOffset = 0;

    //FPSを調整する変数　ここから
    //UPDATE_LOAD_COEFFはCP 負荷が爆発しないようにするための定数で、1フレームの間に使用可能な時間のうち、状態更新に割り当てられる時間の割合の最大値。この時間を超えて処理が行われると、フレームスキップが無効になって処理落ちが発生する。
    this.UPDATE_LOAD_COEFF = 0.5;
    this.FPS = 30;
    this.targetInterval = 1000 / this.FPS;
    this.prevTime = Date.now() - this.targetInterval;
    //FPSを調整する変数　ここまで
  }

  setDom() {
    super.setDom();
    this.scrollWrapper = document.querySelector('.scroll-wrapper');
    this.barbaContainer = document.querySelector('.barba-container');
  }

  initEvents() {
    super.initEvents();

    this.resizeEvent = navigator.userAgent.match(/(iPhone|iPod|iPad)/)
      ? 'orientationchange'
      : 'resize';

    //
    // this.DOMContentloadedHandler.bind(this)();

    this._reload = false;
    this._consentReadyCount = 0;
    if (document.querySelector('#CybotCookiebotDialog')) {
      // console.log('fix CookiebotOnDialogDisplay');
      document.body.style.opacity = 1;
      this._reload = true;
    }
    window.addEventListener('CookiebotOnDialogDisplay', () => {
      // console.log('CookiebotOnDialogDisplay');
      document.body.style.opacity = 1;
      this._reload = true;
    });
    // window.addEventListener('CookiebotOnConsentReady', () => {
    //     console.log('CookiebotOnConsentReady');
    // });
    window.addEventListener(
      'CookiebotOnLoad',
      () => {
        // console.log('CookiebotOnLoad');
        this.DOMContentloadedHandler.bind(this)();
        this.loadedHandler.bind(this)();
      },
      { once: true }
    );
    window.addEventListener('CookiebotOnAccept', () => {
      console.log('CookiebotOnAccept');
      if (this._reload || this._consentReadyCount) window.location.reload();
      this._consentReadyCount++;
      if (Cookiebot.consent.preferences) this.checkLang();
      // marketing
    });
    window.addEventListener('CookiebotOnDecline', () => {
      // console.log('CookiebotOnDecline');
      if (this._reload || this._consentReadyCount) window.location.reload();
      this._consentReadyCount++;
    });
    // window.addEventListener('CookiebotOnDialogInit', () => {
    //     console.log('CookiebotOnDialogInit');
    // });
    // window.addEventListener('CookiebotOnTagsExecuted', () => {
    //     console.log('CookiebotOnTagsExecuted');
    // });

    // window.addEventListener(
    //     'DOMContentLoaded',
    //     this.DOMContentloadedHandler.bind(this)
    // );
    // window.addEventListener('load', this.loadedHandler.bind(this));

    window.addEventListener(
      this.resizeEvent,
      this.resizeHandler.bind(this),
      false
    );
    window.addEventListener('scroll', this.scrollHandler.bind(this), {
      passive: false,
    });

    /* ピッチインピッチアウトによる拡大縮小を禁止 */
    // document.documentElement.addEventListener('touchstart', function (e) {
    //     if (e.touches.length >= 2) {e.preventDefault();}
    // }, {passive: false});

    this.resizeHandler();
    this.enterframe();
  }

  DOMContentloadedHandler(event) {
    this.loading = this.pack.loading = new Loading(this.pack);
    this.background = this.pack.background = new Background(this.pack);
    this.background.start();
    this.transition = this.pack.transition = new Transition(this.pack);
    document.body.style.opacity = 1;
  }

  loadedHandler(event) {
    this.loading.start();
  }

  initContainer() {
    window.scrollTo(0, 0);

    let namespace = this.barbaContainer.dataset.namespace;
    this.pack.current = namespace;

    if (namespace === 'top') {
      this.pack.top = new Top(this.pack);
      this.pack.top.start();
    } else if (namespace === 'about') {
      this.pack.about = new About(this.pack);
      this.pack.about.start();
    } else if (namespace === 'opensource') {
      this.pack.opensource = new Opensource(this.pack);
      this.pack.opensource.start();
    } else if (namespace === 'products') {
      this.pack.products = new Products(this.pack);
      this.pack.products.start();
    } else if (namespace === 'alliance') {
      this.pack.alliance = new Alliance(this.pack);
      this.pack.alliance.start();
    } else if (namespace === 'ourteam') {
      this.pack.ourteam = new Ourteam(this.pack);
      this.pack.ourteam.start();
    } else if (namespace === 'career') {
      this.pack.career = new Career(this.pack);
      this.pack.career.start();
    } else if (namespace === 'media') {
      this.pack.media = new Media(this.pack);
      this.pack.media.start();
    } else if (namespace === 'media_detail') {
      this.pack.media_detail = new MediaDetail(this.pack);
      this.pack.media_detail.start();
    } else if (namespace === 'privacy') {
      this.pack.privacy = new Privacy(this.pack);
      this.pack.privacy.start();
    } else if (namespace === 'contact') {
      this.pack.contact = new Contact(this.pack);
      this.pack.contact.start();
    } else if (namespace === 'page404') {
      this.pack.page404 = new Page404(this.pack);
      this.pack.page404.start();
    }

    this.setCurrent();
  }

  setCurrent() {
    this.pack.header.setCurrent();
  }

  startTransitionIn(callback) {
    // callback.call();
    this.transition.transitIn(callback);
  }

  startTransitionOut() {
    this.transition.transitOut();
  }

  resetPageYOffset() {
    this.pageYOffset = 0;
    this.scrollYOffset = 0;
  }

  scrollDirectly(y) {
    this.scrollYOffset = y;
    this.scrollWrapper.style.transform =
      'translate3d(0,' + -this.scrollYOffset + 'px, 0)';
  }

  scrollHandler() {
    this.pack.pageYOffset = window.pageYOffset;
    if (this.scrollTarget) this.scrollTarget.scrollHandler();
  }

  enterframe() {
    this.enterframeID = window.requestAnimationFrame(
      this.enterframe.bind(this)
    );

    if (this.enterframeTarget && this.enterframeTarget.enterframe)
      this.enterframeTarget.enterframe();

    let currentTime = Date.now();
    let updated = false;
    while (currentTime - this.prevTime > this.targetInterval) {
      updated = true;
      this.prevTime += this.targetInterval;
      const now = Date.now();
      const updateTime = now - currentTime;
      if (updateTime > this.targetInterval * this.UPDATE_LOAD_COEFF) {
        // overloaded
        if (this.prevTime < now - this.targetInterval) {
          // do not accumulate too much
          this.prevTime = now - this.targetInterval;
        }
        break;
      }
    }

    if (updated) {
      //30fpsで処理
      this.enterframeThinOut();
      if (this.enterframeTarget) this.enterframeTarget.enterframeThinOut();
      if (this.background) this.background.enterframeThinOut();
      if (this.transition) this.transition.enterframeThinOut();
    }

    if (this.enterframeIgnoreCnt % 90 === 0) {
      //間引き処理(1 execution per 90 frames)
      this.resizeHandler();
      if (this.every90framesTarget)
        this.every90framesTarget.enterEvery90Frames();
    }

    //前回のスクロール位置（全ての処理が終わってから代入）
    this.pastPageYOffset = this.pack.pastPageYOffset = this.pack.pageYOffset;

    this.enterframeIgnoreCnt++;
  }

  checkOrientation() {
    if (!this.pack.hasTouch) return;
    // if(this.isiPad) return;

    // let rotate_alert = document.querySelector('#rotate_alert');
    // if(window.orientation === 90 || window.orientation === -90) rotate_alert.classList.add("show");
    // else rotate_alert.classList.remove("show");
  }

  enterframeThinOut() {}

  executeResize() {
    super.executeResize();
  }
}
